.categories-menu {
    border-top: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    min-height: 54px;

    .menu {
        width: 100%;
        overflow: scroll;

        ul {
            white-space: nowrap;
            text-align: center;

            li {
                display: inline-block;

                a {
                    padding: 20px;
                    display: block;
                    font-size: 13px;
                    text-transform: uppercase;

                    &:hover {
                        font-weight: bold;
                    }
                }

                &.current_page_item {
                    a {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}