$height: 46px;

.navbar {
    background-color: c(white);
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99997;
    font-size: 0.9rem;
    // border: 1px solid red;

    &__container {
        @extend .grid__container;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0;

        @include over(sm) {
            flex-direction: row;
        }

        & > div {
            flex: 1;
        }
    }

    &__brand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        order: 0;

        @include over(sm) {
            order: 1;
            justify-content: center;
        }

        & > div {
            flex: 1;
            display: flex;
        }

        .navbar__icons {
            margin-left: auto;

            @include over(sm) {
                display: none;
            }
        }
    }

    &__burger, 
    &__logo,
    &__icons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__burger {
        @include hamburger($height);
        justify-content: flex-start;

        @include over(sm) {
            display: none;
        }
    }

    &__logo {
        flex: 2;

        a {
            display: flex;
            height: 64px;
            padding: 10px;

            @include under(sm) {
                height: 48px;
            }

            svg {
                width: auto;
                height: 100%;
                fill: $colorForeground;
            }
        }
    }

    &__icons {
        
        ul {
            display: flex;

            li {
                a {
                    padding: 15px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    vertical-align: middle;

                    .fa-shopping-bag {
                        top: -1px;
                        margin-right: 8px;
                        position: relative;
                    }
                }
            }
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;

        @include over(sm) {
            flex-direction: row;
        }

        &--left {
            order: 1;

            @include over(sm) {
                order: 0;
                justify-content: flex-start;
            }
        }

        &--right {
            order: 2;

            @include over(sm) {
                justify-content: flex-end;
            }
        }

        .navbar__icons {
            
            @include under(sm) {
                display: none;
            }
        }
    }

    &__items {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        
        @include under(sm) {
            width: 100%;
            position: absolute;
            top: 68px;
            left: 0;
            display: none;

            &:first-child {
                border-top: 1px solid whitesmoke;
            }

            &:last-child {
                border-bottom: 1px solid whitesmoke;
            }
        }

        ul {
            display: flex;
            flex-direction: column;

            @include over(sm) {
                flex-direction: row;
            }

            @include under(sm) {
                width: 100%;
            }

            li {
                a {
                    display: block;
                    padding: 15px 10px 12px 10px;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &.current-menu-item {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .cart-d-only {
        @include under(sm) {
            display: none;
        }
    }

    &.active {

        .navbar__items {
            display: flex;
        }
    }
}