@charset "UTF-8";
/*===Mazorca Core===*/
/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

/**
* Basic Styles
* ---------------
* Edítalos o sobreescríbelos
*
*/
/**
 * Super basic stylings
 *
 */
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

html {
  height: 100%; }

html, body {
  min-height: 100%; }

body {
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-family: BlissPro; }

strong {
  font-weight: bold; }

img {
  -ms-interpolation-mode: bicubic; }

.column img, .columns img {
  max-width: 100%; }

img {
  display: block;
  max-width: 100%;
  width: 100%; }

p em {
  font-style: italic; }

section, article, aside, header, footer, nav, figure, figcaption {
  display: block; }

time, mark {
  display: inline-block; }

/**
 * All helpers use the !important keyword, if ever necessary their
 * output might be overriden with the !important keyword in a
 * property located lower in the cascade.
 *
 */
/**
 *	Helpers Index:
 *		Position
 *		Display
 *		Gutters
 *		Paddings and Margins
 *		JS Classes
 *
 */
/*************************** Position ***************************/
.absolute {
  position: absolute !important; }

.relative {
  position: relative !important; }

.fixed {
  position: fixed !important;
  max-width: 100% !important;
  width: 100% !important;
  left: 0; }

.leer-mas__container {
  position: relative;
  overflow: hidden; }

.flip {
  -moz-transform: rotate(180deg) translateX(-50%) !important;
  -o-transform: rotate(180deg) translateX(-50%) !important;
  -webkit-transform: rotate(180deg) translateX(-50%) !important;
  transform: rotate(180deg) translateX(-50%) !important; }

.centerXY {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.text-center {
  text-align: center !important; }

.flex {
  display: flex !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.top56 {
  top: 56px; }

.ontop {
  z-index: 99 !important; }

.z-0 {
  z-index: 0 !important; }

.w100 {
  width: 100% !important; }

.h100 {
  height: 100% !important; }

.op1 {
  opacity: 1 !important; }

.tdn {
  text-decoration: none !important; }

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important; }

/*************************** Layout ***************************/
.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

/*************************** Display ***************************/
.d-bl {
  display: block !important; }

.inline {
  display: inline !important; }

.inline-block {
  display: inline-block !important; }

/*************************** Gutters ***************************/
/*************************** Paddings and Margins ***************************/
.p0 {
  padding: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.m0 {
  margin: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb40 {
  margin-bottom: 40px !important; }

/*************************** Arrows ***************************/
.icon-arrow-up:after {
  content: '\e801'; }

.icon-arrow-down:after {
  content: '\e800'; }

/*************************** Drags, Selects ***************************/
.undraggable-unselectable-cascading *,
.undraggable-unselectable {
  user-drag: none !important;
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important; }

.unselectable {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important; }

/*************************** Text ***************************/
.ttu {
  text-transform: uppercase !important; }

.ttn {
  text-transform: none !important; }

/*************************** Animations ***************************/
.transition-fast {
  transition: all 0.3s; }

.transition-slow {
  transition: all 1s; }

.hover-scale-up:hover {
  transform: scale(1.2) !important; }

/*************************** JS Classes ***************************/
[v-cloak] {
  opacity: 0; }

.noScroll_JS, .no-scroll {
  overflow: hidden !important; }

/*************************** Bootstrap ***************************/
.hidden {
  display: none !important; }

/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

html {
  font-size: 10px; }

/**
 * Declare fonts using the Mixin
 */
@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-Light.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-Light.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-Light.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-LightItalic.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-LightItalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-LightItalic.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-Regular.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-Regular.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-Regular.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-Italic.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-Italic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-Italic.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-Medium.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-Medium.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-Medium.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-MediumItalic.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-MediumItalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-MediumItalic.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-Semibold.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-Semibold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-Semibold.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-SemiboldItalic.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-SemiboldItalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-SemiboldItalic.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-Bold.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-Bold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-Bold.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "HKGrotesk";
  src: url("../fonts/HKGrotesk/HKGrotesk-BoldItalic.eot");
  src: url("../fonts/HKGrotesk/HKGrotesk-BoldItalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/HKGrotesk/HKGrotesk-BoldItalic.woff") format("woff");
  src: url("../fonts/HKGrotesk/HKGrotesk-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-stretch: normal; }

/*
*   The Variables
*   Feel free to play with them!
*/
/*Set to "true" for -webkit-,-moz-appereance:none
                            useful for editing radio buttons amog other things*/
/*similar as above but for selects*/
/*
*   If you need to style basic layout and stuff go to your
*   "modules/_contactForm.scss" or similar file
*/
/*Input Elements' Params*/
input, textarea {
  background-color: #FFFFFF;
  border: 1px solid;
  border-radius: initial;
  padding: 0 .5em;
  -moz-appearance: none;
  -webkit-appearance: none; }

input, textarea {
  width: 100%; }

textarea {
  resize: vertical; }

input[type="submit"],
input[type="button"] {
  display: block; }

input[type="date"],
input[type="time"],
input[type="button"] {
  width: auto; }

input::-webkit-datetime-edit {
  color: black; }

input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 1; }

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
  padding: 0;
  height: 1rem;
  width: 1rem;
  vertical-align: text-top; }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    background-color: black; }

input[type="radio"] {
  border-radius: 100%; }

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer; }

input:focus, select:focus, textarea:focus, button:focus {
  outline: none; }

html, body {
  height: 100%;
  font-size: 16px; }

body {
  display: flex;
  flex-direction: column;
  font-family: "HKGrotesk", sans-serif; }

.content-area {
  width: 100%; }


.divisor {
  width: 100%;
  border-bottom: 1px solid; }

/*_________________________ Grid _________________________ */
.grid__row {
  position: relative;
  width: 100%;
  min-height: 1px; }

.grid__container, .navbar__container, .main-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%; }

.grid__container---fluid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.grid__col-1-1, .grid__col-1-2, .grid__col-1-3 {
  position: relative; }

.grid__col-1-1 {
  width: 100%; }

.grid__col-1-2 {
  width: 50%; }

.grid__col-1-2:nth-of-type(odd) {
  padding-right: 10px; }

.grid__col-1-2:nth-of-type(even) {
  padding-left: 10px; }

.grid__col-1-3 {
  width: 33.33333%; }

.grid__col-1-3:nth-of-type(3n-2) {
  padding-right: 13.33333px; }

.grid__col-1-3:nth-of-type(3n-1) {
  padding-right: 6.66667px;
  padding-left: 6.66667px; }

.grid__col-1-3:nth-of-type(3n) {
  padding-left: 13.33333px; }

.grid__col-1-4 {
  width: 25%; }

.grid__col-1-4:nth-of-type(4n-3) {
  padding-right: 13.33333px; }

.grid__col-1-4:nth-of-type(4n-2) {
  padding-right: 6.66667px;
  padding-left: 6.66667px; }

.grid__col-1-4:nth-of-type(4n-1) {
  padding-right: 6.66667px;
  padding-left: 6.66667px; }

.grid__col-1-4:nth-of-type(4n) {
  padding-left: 13.33333px; }

.grid__box {
  position: relative;
  width: 100%; }

.splash {
  margin: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 40px);
  min-height: 650px;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .splash {
      margin: 10px;
      height: calc(100vh - 20px); } }
  .splash__overlay {
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .splash .grid__container, .splash .navbar__container, .splash .main-wrap,
  .splash .grid__row {
    height: 100%; }
  .splash__col {
    width: 100%;
    max-width: 650px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
  .splash__box--top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%; }
  .splash__box--bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; }
  .splash__logo {
    height: 52px;
    font-family: "Playfair Display", serif;
    margin: 15px 0; }
    .splash__logo svg {
      height: 100%;
      width: auto;
      fill: white; }
  .splash__text {
    color: white;
    font-family: "Playfair Display", serif;
    font-size: 16px; }
  .splash__social ul {
    display: flex;
    justify-content: center;
    margin: 20px 0; }
    .splash__social ul li a {
      display: inline-block;
      color: white;
      font-size: 16px;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Playfair Display", serif; }
      .splash__social ul li a:hover {
        text-decoration: underline; }
  .splash__contact {
    margin: 15px auto;
    font-family: "Playfair Display", serif;
    text-align: center;
    color: white; }
    .splash__contact .ttl {
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 1em; }
    .splash__contact .content {
      font-size: 14px;
      line-height: 1.5em; }
      .splash__contact .content a:hover {
        text-decoration: underline; }
  .splash__form {
    font-family: "Playfair Display", serif;
    margin: 40px 0; }
    .splash__form .mc-field-group label {
      color: white;
      font-size: 14px;
      margin-bottom: 10px;
      display: inline-block; }
    .splash__form .mc-field-group input {
      border: 0;
      color: black;
      font-size: 14px;
      font-family: "Playfair Display", serif;
      padding: 10px; }
    .splash__form .mc-field-group .mce_inline_error {
      font-size: 12px;
      padding: 10px !important; }
    .splash__form .response {
      font-size: 14px;
      color: white;
      display: inline-block;
      margin: 10px 0; }
    .splash__form #mc-embedded-subscribe {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid white;
      background-color: transparent;
      color: white;
      font-family: "Playfair Display", serif;
      font-size: 14px; }
      .splash__form #mc-embedded-subscribe:hover {
        background-color: white;
        color: black;
        cursor: pointer; }

.navbar {
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99997;
  font-size: 0.9rem; }
  .navbar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0; }
    @media only screen and (min-width: 768px) {
      .navbar__container {
        flex-direction: row; } }
    .navbar__container > div {
      flex: 1; }
  .navbar__brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    order: 0; }
    @media only screen and (min-width: 768px) {
      .navbar__brand {
        order: 1;
        justify-content: center; } }
    .navbar__brand > div {
      flex: 1;
      display: flex; }
    .navbar__brand .navbar__icons {
      margin-left: auto; }
      @media only screen and (min-width: 768px) {
        .navbar__brand .navbar__icons {
          display: none; } }
  .navbar__burger, .navbar__logo, .navbar__icons {
    display: flex;
    align-items: center;
    justify-content: center; }
  .navbar__burger {
    cursor: pointer;
    display: block;
    height: 46px;
    position: relative;
    width: 46px;
    justify-content: flex-start; }
    .navbar__burger span {
      background-color: #212121;
      display: block;
      height: 2px;
      left: 50%;
      margin-left: -10px;
      position: absolute;
      top: 50%;
      transition: none 0.3 ease;
      transition-property: background, left, opacity, transform;
      width: 20px; }
      .navbar__burger span:nth-child(1) {
        margin-top: -6px; }
      .navbar__burger span:nth-child(2) {
        margin-top: -1px; }
      .navbar__burger span:nth-child(3) {
        margin-top: 4px; }
    .navbar__burger:hover {
      background-color: transparent; }
    .navbar__burger.is-active span {
      background-color: #212121; }
      .navbar__burger.is-active span:nth-child(1) {
        margin-left: -5px;
        transform: rotate(45deg);
        transform-origin: left top; }
      .navbar__burger.is-active span:nth-child(2) {
        opacity: 0; }
      .navbar__burger.is-active span:nth-child(3) {
        margin-left: -5px;
        transform: rotate(-45deg);
        transform-origin: left bottom; }
    @media only screen and (min-width: 768px) {
      .navbar__burger {
        display: none; } }
  .navbar__logo {
    flex: 2; }
    .navbar__logo a {
      display: flex;
      height: 64px;
      padding: 10px; }
      @media only screen and (max-width: 768px) {
        .navbar__logo a {
          height: 48px; } }
      .navbar__logo a svg {
        width: auto;
        height: 100%;
        fill: #212121; }
  .navbar__icons ul {
    display: flex; }
    .navbar__icons ul li a {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle; }
      .navbar__icons ul li a .fa-shopping-bag {
        top: -1px;
        margin-right: 8px;
        position: relative; }
  .navbar__menu {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 768px) {
      .navbar__menu {
        flex-direction: row; } }
    .navbar__menu--left {
      order: 1; }
      @media only screen and (min-width: 768px) {
        .navbar__menu--left {
          order: 0;
          justify-content: flex-start; } }
    .navbar__menu--right {
      order: 2; }
      @media only screen and (min-width: 768px) {
        .navbar__menu--right {
          justify-content: flex-end; } }
    @media only screen and (max-width: 768px) {
      .navbar__menu .navbar__icons {
        display: none; } }
  .navbar__items {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; }
    @media only screen and (max-width: 768px) {
      .navbar__items {
        width: 100%;
        position: absolute;
        top: 68px;
        left: 0;
        display: none; }
        .navbar__items:first-child {
          border-top: 1px solid whitesmoke; }
        .navbar__items:last-child {
          border-bottom: 1px solid whitesmoke; } }
    .navbar__items ul {
      display: flex;
      flex-direction: column; }
      @media only screen and (min-width: 768px) {
        .navbar__items ul {
          flex-direction: row; } }
      @media only screen and (max-width: 768px) {
        .navbar__items ul {
          width: 100%; } }
      .navbar__items ul li a {
        display: block;
        padding: 15px 10px 12px 10px;
        text-transform: uppercase; }
        .navbar__items ul li a:hover {
          text-decoration: underline; }
      .navbar__items ul li.current-menu-item a {
        text-decoration: underline; }
  @media only screen and (max-width: 768px) {
    .navbar .cart-d-only {
      display: none; } }
  .navbar.active .navbar__items {
    display: flex; }

.footer__newsletter {
  border-top: 1px solid whitesmoke;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 50% 50%; }
  .footer__newsletter .ttl {
    font-family: "HKGrotesk", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1em;
    font-size: 14px; }
  @media only screen and (max-width: 768px) {
    .footer__newsletter {
      grid-template-columns: 100%; } }
  .footer__newsletter-form {
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 768px) {
      .footer__newsletter-form {
        align-items: center; } }
    .footer__newsletter-form #mc_embed_signup {
      margin-bottom: 1em; }
    .footer__newsletter-form .form-group {
      display: flex;
      align-items: stretch;
      font-family: "Playfair Display", serif; }
    .footer__newsletter-form input[type="email"] {
      background-color: white;
      padding: 10px;
      font-size: 14px;
      margin-bottom: 1em;
      min-width: 250px;
      min-height: 44px; }
      @media only screen and (max-width: 768px) {
        .footer__newsletter-form input[type="email"] {
          min-width: 150px; } }
    .footer__newsletter-form input[type="submit"] {
      background-color: #212121;
      padding: 12.5px 15px;
      font-size: 14px;
      border-color: #212121;
      color: white;
      cursor: pointer;
      text-transform: uppercase;
      border-radius: 0 !important;
      min-height: 44px; }
      .footer__newsletter-form input[type="submit"]:hover {
        background-color: #3b3b3b; }
    .footer__newsletter-form div.mce_inline_error {
      margin-bottom: 0 !important;
      font-family: "HKGrotesk", sans-serif;
      background: transparent !important;
      color: #69070A !important;
      font-weight: normal !important;
      padding: 5px 0 !important;
      font-size: 14px; }
  .footer__newsletter-social {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media only screen and (max-width: 768px) {
      .footer__newsletter-social {
        align-items: center; } }
    .footer__newsletter-social ul {
      display: flex; }
      .footer__newsletter-social ul li a {
        display: block;
        margin: 10px;
        font-size: 22px; }
        .footer__newsletter-social ul li a:hover {
          color: #3b3b3b; }

.footer__menus {
  border-top: 1px solid whitesmoke;
  display: grid;
  grid-template-columns: 20% 20% 20% 40%;
  padding: 40px 0; }
  @media only screen and (max-width: 768px) {
    .footer__menus {
      grid-template-columns: 100%; } }
  .footer__menus .ttl {
    margin-bottom: 1em;
    font-weight: bold; }
  .footer__menus nav {
    margin-bottom: 20px; }
    .footer__menus nav ul li {
      margin-bottom: 0.5em; }
      .footer__menus nav ul li a {
        font-size: 14px; }
        .footer__menus nav ul li a:hover {
          text-decoration: underline; }
  .footer__menus p {
    line-height: 1.2;
    font-size: 14px; }
    .footer__menus p a:hover {
      text-decoration: underline; }
    .footer__menus p strong {
      font-weight: bold; }

.footer__logos {
  border-top: 1px solid whitesmoke;
  padding: 40px 0; }
  .footer__logos ul {
    display: flex;
    justify-content: center; }
    .footer__logos ul li {
      font-size: 24px;
      margin: 10px;
      color: #666666; }
      .footer__logos ul li svg {
        font-size: 26px;
        fill: #666666; }
        .footer__logos ul li svg.fa-cc-oxxo, .footer__logos ul li svg.fa-cc-spei {
          width: 2.025em; }

.footer__copyright {
  border-top: 1px solid whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  font-size: 12px;
  color: #666666; }
  .footer__copyright .logo {
    width: 120px;
    margin-bottom: 1em; }
    .footer__copyright .logo svg {
      fill: #666666; }

/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
.regalaemociones-theme p.demo_store,
.regalaemociones-theme .woocommerce-store-notice {
  position: relative;
  box-shadow: none;
  font-size: 0.9rem;
  background: #FFFFFF;
  color: #666666; }
  .regalaemociones-theme p.demo_store a,
  .regalaemociones-theme .woocommerce-store-notice a {
    display: none; }

.regalaemociones-theme .woocommerce, .regalaemociones-theme.woocommerce {
  width: 100%; }
  .regalaemociones-theme .woocommerce .woocommerce-breadcrumb, .regalaemociones-theme.woocommerce .woocommerce-breadcrumb {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 0; }
    .regalaemociones-theme .woocommerce .woocommerce-breadcrumb a:hover, .regalaemociones-theme.woocommerce .woocommerce-breadcrumb a:hover {
      text-decoration: underline; }
  .regalaemociones-theme .woocommerce span.onsale, .regalaemociones-theme.woocommerce span.onsale {
    min-height: auto;
    min-width: auto;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #FC4482;
    color: #FFFFFF;
    border-radius: 0;
    padding: 0 10px; }
  .regalaemociones-theme .woocommerce div.product form.cart div.quantity, .regalaemociones-theme.woocommerce div.product form.cart div.quantity {
    display: none !important; }
  .regalaemociones-theme .woocommerce div.product form.cart .variations td, .regalaemociones-theme.woocommerce div.product form.cart .variations td {
    padding-bottom: 10px; }
    .regalaemociones-theme .woocommerce div.product form.cart .variations td.label, .regalaemociones-theme.woocommerce div.product form.cart .variations td.label {
      width: 1px; }
  .regalaemociones-theme .woocommerce div.product form.cart .variations select, .regalaemociones-theme.woocommerce div.product form.cart .variations select {
    padding: 10px;
    border-radius: 0;
    font-size: 12px;
    background-color: white; }
  .regalaemociones-theme .woocommerce div.product div.summary p.price, .regalaemociones-theme.woocommerce div.product div.summary p.price {
    text-align: left; }
  .regalaemociones-theme .woocommerce div.product .button, .regalaemociones-theme.woocommerce div.product .button {
    width: 100%;
    background: #FC4482;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: "HKGrotesk", sans-serif;
    font-size: 1.1rem; }
    @media only screen and (min-width: 768px) {
      .regalaemociones-theme .woocommerce div.product .button, .regalaemociones-theme.woocommerce div.product .button {
        max-width: 400px; } }
  .regalaemociones-theme .woocommerce div.product .product_title, .regalaemociones-theme.woocommerce div.product .product_title {
    font-family: "Abril Fatface", sans-serif;
    font-size: 36px;
    margin-bottom: 0.5em;
    font-weight: 400; }
  .regalaemociones-theme .woocommerce div.product span.price,
  .regalaemociones-theme .woocommerce div.product p.price, .regalaemociones-theme.woocommerce div.product span.price,
  .regalaemociones-theme.woocommerce div.product p.price {
    color: #FC4482;
    margin-bottom: 0.74em;
    text-align: center; }
    .regalaemociones-theme .woocommerce div.product span.price ins,
    .regalaemociones-theme .woocommerce div.product p.price ins, .regalaemociones-theme.woocommerce div.product span.price ins,
    .regalaemociones-theme.woocommerce div.product p.price ins {
      text-decoration: none; }
  .regalaemociones-theme .woocommerce div.product div.images, .regalaemociones-theme.woocommerce div.product div.images {
    margin-bottom: 3em; }
  .regalaemociones-theme .woocommerce ul.products li.product h3,
  .regalaemociones-theme .woocommerce ul.products li.product .woocommerce-loop-product__title,
  .regalaemociones-theme .woocommerce ul.products li.product .woocommerce-loop-category__title, .regalaemociones-theme.woocommerce ul.products li.product h3,
  .regalaemociones-theme.woocommerce ul.products li.product .woocommerce-loop-product__title,
  .regalaemociones-theme.woocommerce ul.products li.product .woocommerce-loop-category__title {
    text-align: center;
    font-family: "HKGrotesk", sans-serif; }
  .regalaemociones-theme .woocommerce ul.products li.product .price, .regalaemociones-theme.woocommerce ul.products li.product .price {
    color: #FC4482;
    text-align: center; }
    .regalaemociones-theme .woocommerce ul.products li.product .price ins, .regalaemociones-theme.woocommerce ul.products li.product .price ins {
      text-decoration: none; }
    .regalaemociones-theme .woocommerce ul.products li.product .price .from, .regalaemociones-theme.woocommerce ul.products li.product .price .from {
      font-size: 0.67em;
      margin: -2px 0 0 0;
      text-transform: uppercase;
      color: rgba(181, 139, 153, 0.5); }
  .regalaemociones-theme .woocommerce .woocommerce-ordering, .regalaemociones-theme.woocommerce .woocommerce-ordering {
    margin: 0 0 2em; }
    .regalaemociones-theme .woocommerce .woocommerce-ordering select, .regalaemociones-theme.woocommerce .woocommerce-ordering select {
      vertical-align: top;
      padding: 10px;
      border: 1px solid #212121;
      border-radius: 0;
      background-color: white;
      font-size: 13px;
      font-weight: 300; }
  .regalaemociones-theme .woocommerce .quantity .qty, .regalaemociones-theme.woocommerce .quantity .qty {
    padding: 10px;
    width: 4em; }
  .regalaemociones-theme .woocommerce .cart .button,
  .regalaemociones-theme .woocommerce .cart input.button, .regalaemociones-theme.woocommerce .cart .button,
  .regalaemociones-theme.woocommerce .cart input.button {
    border-radius: 0; }
  .regalaemociones-theme .woocommerce input.button.alt,
  .regalaemociones-theme .woocommerce button.button.alt,
  .regalaemociones-theme .woocommerce a.button.alt, .regalaemociones-theme.woocommerce input.button.alt,
  .regalaemociones-theme.woocommerce button.button.alt,
  .regalaemociones-theme.woocommerce a.button.alt {
    background-color: #FC4482; }
  .regalaemociones-theme .woocommerce table.cart td.actions .coupon, .regalaemociones-theme.woocommerce table.cart td.actions .coupon {
    display: flex;
    align-items: stretch;
    flex: 1; }
  .regalaemociones-theme .woocommerce table.cart td.actions input,
  .regalaemociones-theme .woocommerce table.cart td.actions button, .regalaemociones-theme.woocommerce table.cart td.actions input,
  .regalaemociones-theme.woocommerce table.cart td.actions button {
    flex: 1; }
  .regalaemociones-theme .woocommerce table.cart td.actions .input-text, .regalaemociones-theme.woocommerce table.cart td.actions .input-text {
    width: 180px; }
  .regalaemociones-theme .woocommerce .cart-collaterals .cart_totals h2, .regalaemociones-theme.woocommerce .cart-collaterals .cart_totals h2 {
    font-size: 24px;
    margin-bottom: 1em; }
  .regalaemociones-theme .woocommerce table.shop_table, .regalaemociones-theme.woocommerce table.shop_table {
    border: 0; }
  .regalaemociones-theme .woocommerce form.checkout_coupon,
  .regalaemociones-theme .woocommerce form.login,
  .regalaemociones-theme .woocommerce form.register, .regalaemociones-theme.woocommerce form.checkout_coupon,
  .regalaemociones-theme.woocommerce form.login,
  .regalaemociones-theme.woocommerce form.register {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #d3ced2; }

.regalaemociones-theme .woocommerce-product-details__short-description {
  margin-bottom: 1em; }

.regalaemociones-theme .woocommerce-products-header__title {
  font-family: "Playfair Display", serif;
  font-size: 32px;
  margin-bottom: 1em; }

.regalaemociones-theme .product_meta {
  display: flex;
  flex-direction: column; }
  .regalaemociones-theme .product_meta span {
    margin-bottom: 1em; }
    .regalaemociones-theme .product_meta span span {
      color: #666666; }
    .regalaemociones-theme .product_meta span a {
      text-decoration: underline; }

.regalaemociones-theme .cross-sells,
.regalaemociones-theme .upsells.products,
.regalaemociones-theme .related.products {
  clear: both; }
  .regalaemociones-theme .cross-sells h2,
  .regalaemociones-theme .upsells.products h2,
  .regalaemociones-theme .related.products h2 {
    font-family: "HKGrotesk", sans-serif;
    font-size: 21px;
    margin-top: 2px;
    margin-bottom: 2.5em; }

.regalaemociones-theme .single_variation_wrap .woocommerce-variation.single_variation {
  margin-bottom: 2em; }
  .regalaemociones-theme .single_variation_wrap .woocommerce-variation.single_variation .woocommerce-variation-description {
    line-height: 1.3; }

.regalaemociones-theme .woocommerce-error,
.regalaemociones-theme .woocommerce-info,
.regalaemociones-theme .woocommerce-message {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0 1em 0;
  width: 100%; }
  .regalaemociones-theme .woocommerce-error .button,
  .regalaemociones-theme .woocommerce-info .button,
  .regalaemociones-theme .woocommerce-message .button {
    order: 1;
    background-color: transparent; }
  .regalaemociones-theme .woocommerce-error:after,
  .regalaemociones-theme .woocommerce-info:after,
  .regalaemociones-theme .woocommerce-message:after {
    content: none; }
  .regalaemociones-theme .woocommerce-error strong,
  .regalaemociones-theme .woocommerce-info strong,
  .regalaemociones-theme .woocommerce-message strong {
    font-weight: bold; }
  .regalaemociones-theme .woocommerce-error a:hover,
  .regalaemociones-theme .woocommerce-info a:hover,
  .regalaemociones-theme .woocommerce-message a:hover {
    text-decoration: underline; }

.regalaemociones-theme ul.woocommerce-error {
  flex-direction: column; }

.regalaemociones-theme .woocommerce-message {
  border-top-color: #FC4482; }
  .regalaemociones-theme .woocommerce-message::before {
    color: #FC4482;
    top: 1.5em; }
  .regalaemociones-theme .woocommerce-message .wc-forward {
    background-color: transparent;
    color: #FC4482; }

.regalaemociones-theme .woocommerce-info {
  border-top-color: #212121; }
  .regalaemociones-theme .woocommerce-info:before {
    color: #212121; }

.regalaemociones-theme .shipping-calculator-form {
  padding: 20px 0; }

.regalaemociones-theme .shipping-calculator-button {
  text-decoration: underline; }

.regalaemociones-theme .woocommerce-cart-form {
  margin: 40px 0; }

.regalaemociones-theme .select2-container--default .select2-selection--single {
  border-radius: 0; }

.regalaemociones-theme .input-text {
  padding: 9px !important;
  font-size: 13px; }

.regalaemociones-theme form .form-row .input-checkbox {
  display: inline-block; }

.regalaemociones-theme #customer_details {
  margin-bottom: 2em; }

.regalaemociones-theme #order_review_heading {
  font-size: 24px;
  margin-bottom: 1em; }

.regalaemociones-theme .woocommerce-checkout #payment {
  background-color: transparent; }

.regalaemociones-theme .woocommerce-MyAccount-navigation {
  width: 100%;
  margin-bottom: 1em; }
  .regalaemociones-theme .woocommerce-MyAccount-navigation ul {
    display: flex;
    flex-flow: row wrap; }
    .regalaemociones-theme .woocommerce-MyAccount-navigation ul li a {
      display: block;
      margin: 1em 2em 1em 0; }
      .regalaemociones-theme .woocommerce-MyAccount-navigation ul li a:hover {
        text-decoration: underline; }
    .regalaemociones-theme .woocommerce-MyAccount-navigation ul li.is-active a {
      text-decoration: underline; }

.regalaemociones-theme .woocommerce-MyAccount-content {
  width: 100%; }
  .regalaemociones-theme .woocommerce-MyAccount-content p {
    margin-bottom: 0.8em; }
  .regalaemociones-theme .woocommerce-MyAccount-content .woocommerce-Addresses {
    margin: 2em 0; }
  .regalaemociones-theme .woocommerce-MyAccount-content .woocommerce-Address-title {
    font-weight: bold;
    margin-bottom: 1em; }

.regalaemociones-theme table.cart img {
  width: 80px; }

.regalaemociones-theme #payment {
  background-color: white; }
  .regalaemociones-theme #payment ul.payment_methods li img {
    width: auto !important;
    display: inline-block; }
  .regalaemociones-theme #payment ul.payment_methods li a {
    text-decoration: underline; }
  .regalaemociones-theme #payment ul.payment_methods li h4 {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 15px; }
  .regalaemociones-theme #payment div.payment_box {
    background-color: white;
    border: 1px solid whitesmoke; }
    .regalaemociones-theme #payment div.payment_box:before {
      display: none; }
    .regalaemociones-theme #payment div.payment_box .form-row {
      padding: 0; }
      .regalaemociones-theme #payment div.payment_box .form-row select {
        padding: 10px;
        width: 100%;
        border-radius: 0;
        font-size: 13px;
        background-color: white; }
      .regalaemociones-theme #payment div.payment_box .form-row input {
        border-radius: 0 !important; }
  .regalaemociones-theme #payment #place_order {
    font-size: 18px; }
  .regalaemociones-theme #payment .payment_method_paypal .about_paypal {
    text-decoration: underline; }

#byconsolewooodt_delivery_type_field {
  display: none; }

label span.optional {
  display: none; }

.instagram {
  padding: 80px 0; }
  .instagram__panel {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: center; }
  .instagram__ttl {
    font-family: "HKGrotesk", sans-serif;
    font-size: 18px;
    margin-bottom: 0.8em;
    display: block;
    text-align: center;
    color: #666666; }
  .instagram__post {
    min-width: 160px;
    display: inline-block;
    margin: 2.5px; }
    @media only screen and (max-width: 768px) {
      .instagram__post {
        min-width: 150px; } }
  .instagram__box {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative; }
    .instagram__box-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: transparent;
      font-size: 18px; }
      .instagram__box-content:hover {
        background-color: rgba(0, 0, 0, 0.3);
        color: white; }

.vip {
  padding: 80px 0;
  text-align: center;
  position: relative; }
  .vip:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    height: 1px;
    width: 40px;
    /* or 100px */
    border-bottom: 2px solid #212121; }
  .vip:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    height: 1px;
    width: 40px;
    /* or 100px */
    border-bottom: 2px solid #212121; }
  .vip__ttl {
    font-family: "Playfair Display", serif;
    font-size: 42px;
    margin-bottom: 0.9em; }
  .vip__button {
    background-color: #FC4482;
    color: #FFFFFF;
    letter-spacing: 1px;
    font-family: "HKGrotesk", sans-serif;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: bold; }
    .vip__button:hover {
      opacity: 0.8; }

.categories-menu {
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  min-height: 54px; }
  .categories-menu .menu {
    width: 100%;
    overflow: scroll; }
    .categories-menu .menu ul {
      white-space: nowrap;
      text-align: center; }
      .categories-menu .menu ul li {
        display: inline-block; }
        .categories-menu .menu ul li a {
          padding: 20px;
          display: block;
          font-size: 13px;
          text-transform: uppercase; }
          .categories-menu .menu ul li a:hover {
            font-weight: bold; }
        .categories-menu .menu ul li.current_page_item a {
          font-weight: bold; }

.featured-products {
  margin-top: 40px; }
  .featured-products__ttl {
    font-family: "Playfair Display", serif;
    font-size: 24px !important;
    margin-bottom: 1.5em; }

.home-categories {
  width: 100%;
  padding: 40px 0; }
  .home-categories__banners {
    display: flex;
    flex-flow: row wrap;
    justify-content: center; }
  .home-categories__box {
    width: 33%;
    padding: 20px; }
    @media only screen and (max-width: 768px) {
      .home-categories__box {
        width: 50%; } }
    @media only screen and (max-width: 480px) {
      .home-categories__box {
        width: 100%; } }
  .home-categories__banner {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block; }
    .home-categories__banner .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.1); }
      .home-categories__banner .content:hover {
        background-color: transparent; }
      .home-categories__banner .content span {
        font-family: "Playfair Display", serif;
        font-weight: bold;
        color: white;
        font-size: 24px; }

.navbar-search {
  padding: 20px 0;
  position: relative;
  border-top: 1px solid whitesmoke;
  display: none; }
  .navbar-search form {
    display: flex; }
  .navbar-search input {
    padding: 10px;
    font-size: 13px; }
  .navbar-search button[type="submit"] {
    width: 150px;
    font-size: 13px;
    text-transform: uppercase;
    background-color: #212121;
    color: #FFFFFF;
    border: 0;
    cursor: pointer; }
  .navbar-search__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: 0;
    font-size: 14px;
    background-color: transparent; }
    @media only screen and (max-width: 768px) {
      .navbar-search__close {
        top: 0px;
        right: 0px; } }

.page__img {
  margin: 1em auto;
  width: auto; }
  .page__img-box {
    margin-bottom: 40px; }

.page-content {
  margin: 40px 0;
  width: 100%; }
  .page-content__container {
    width: 100%;
    max-width: 650px;
    margin: 0 auto; }

.page__ttl {
  font-family: "Abril Fatface", sans-serif;
  font-size: 54px !important;
  text-align: center;
  margin-bottom: 0.7em; }

.page__text {
  font-size: 18px;
  line-height: 1.5; }
  .page__text h1, .page__text h2, .page__text h3, .page__text h4 h5, .page__text h6 {
    font-size: 1.2em;
    margin-bottom: 0.8em;
    font-weight: bold; }
  .page__text p, .page__text dl, .page__text ol, .page__text ul {
    margin-bottom: 1em; }
  .page__text ol {
    list-style-type: upper-roman;
    padding-left: 2em; }
  .page__text ul {
    list-style-type: disc;
    padding-left: 2em; }
  .page__text li {
    margin-bottom: 0.5em; }
  .page__text img {
    width: 100%;
    height: auto; }

.main-wrap {
  flex: 1 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.5; }
  .main-wrap h1, .main-wrap h2, .main-wrap h3, .main-wrap h4 h5, .main-wrap h6 {
    font-size: 1.2em;
    font-weight: bold;
    display: block;
    width: 100%; }
    .main-wrap h1.page-title, .main-wrap h1.ttl, .main-wrap h2.page-title, .main-wrap h2.ttl, .main-wrap h3.page-title, .main-wrap h3.ttl, .main-wrap h4 h5.page-title, .main-wrap h4 h5.ttl, .main-wrap h6.page-title, .main-wrap h6.ttl {
      font-size: 24px; }
  .main-wrap p, .main-wrap dl, .main-wrap ol, .main-wrap ul {
    margin-bottom: 1em;
    width: 100%; }
  .main-wrap ol {
    list-style-type: upper-roman;
    padding-left: 2em; }
  .main-wrap ul {
    list-style-type: disc;
    padding-left: 2em; }
  .main-wrap li {
    margin-bottom: 0.5em; }
  .main-wrap img {
    width: 100%;
    height: auto; }

.not-found {
  width: 100%;
  padding: 40px 0; }
  .not-found__col {
    text-align: center; }
  .not-found__logo {
    width: 200px;
    margin: 20px auto; }
  .not-found__ttl {
    font-family: "Abril Fatface", sans-serif;
    font-weight: normal !important;
    color: #212121;
    font-size: 72px !important;
    line-height: 0.7;
    margin: 42px auto; }
    .not-found__ttl small {
      font-family: "HKGrotesk", sans-serif;
      font-weight: bold;
      font-size: 32px !important; }
  .not-found__content a {
    background-color: #EBE9EB;
    color: #545454;
    border: 0;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3px; }

.row {
  display: flex;
  flex-flow: row wrap; }

.col-md-12 {
  padding: 0 20px;
  width: 100%; }

.col-md-4 {
  padding: 0 20px;
  width: 33%; }

.col-md-8 {
  padding: 0 20px;
  width: 66%; }

.layout__ttl {
  font-weight: bold;
  font-family: "HKGrotesk", sans-serif;
  font-size: 128px;
  margin: 54px 0;
  line-height: 54px;
  color: #7A7D7E;
  text-align: center; }
  .layout__ttl small {
    font-size: 28px; }

.layout__content {
  font-size: 14px;
  text-align: center; }
  .layout__content a:hover {
    text-decoration: underline; }
