.footer {

    &__newsletter {
        border-top: 1px solid whitesmoke;
        padding: 40px 0;
        display: grid;
        grid-template-columns: 50% 50%;

        .ttl {
            font-family: f(sans);
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 1em;
            font-size: 14px;
        }

        @include under(sm) {
            grid-template-columns: 100%;
        }

        &-form {
            display: flex;
            flex-direction: column;

            @include under(sm) {
                align-items: center;
            }

            #mc_embed_signup {
                margin-bottom: 1em;
            }

            .form-group {
                display: flex;
                align-items: stretch;
                font-family: f(serif);
            }
            input[type="email"] {
                background-color: white;
                padding: 10px;
                font-size: 14px;
                margin-bottom: 1em;
                min-width: 250px;
                min-height: 44px;

                @include under(sm) {
                    min-width: 150px;
                }
            }
            input[type="submit"] {
                background-color: $colorForeground;
                padding: 12.5px 15px;
                font-size: 14px;
                border-color: $colorForeground;
                color: white;
                cursor: pointer;
                text-transform: uppercase;
                border-radius: 0 !important;
                min-height: 44px;

                &:hover {
                    background-color: $colorForegroundLighten;
                }
            }
            div.mce_inline_error {
                margin-bottom: 0 !important;
                font-family: f(sans);
                background: transparent !important;
                color: #69070A !important;
                font-weight: normal !important;
                padding: 5px 0 !important;
                font-size: 14px;
            }
        }

        &-social {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @include under(sm) {
                align-items: center;
            }

            ul {
                display: flex;

                li {

                    a {
                        display: block;
                        margin: 10px;
                        font-size: 22px;

                        &:hover {
                            color: $colorForegroundLighten;
                        }
                    }
                }
            }
        }
    }

    &__menus {
        border-top: 1px solid whitesmoke;
        display: grid;
        grid-template-columns: 20% 20% 20% 40%;
        padding: 40px 0;

        @include under(sm) {
            grid-template-columns: 100%;
        }

        .ttl {
            margin-bottom: 1em;
            font-weight: bold;
        }

        nav {
            margin-bottom: 20px;
            ul {
                li {
                    margin-bottom: 0.5em;

                    a {
                        font-size: 14px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        p {
            line-height: 1.2;
            font-size: 14px;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }

            strong {
                font-weight: bold;
            }
        }
    }

    &__logos {
        border-top: 1px solid whitesmoke;
        padding: 40px 0;

        ul {
            display: flex;
            justify-content: center;

            li {
                font-size: 24px;
                margin: 10px;
                color: c(grey);

                svg {
                    font-size: 26px;
                    fill: c(grey);

                    &.fa-cc-oxxo,
                    &.fa-cc-spei {
                        width: 2.025em;
                    }
                }
            }
        }
    }

    &__copyright {
        border-top: 1px solid whitesmoke;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
        font-size: 12px;
        color: c(grey);

        .logo {
            width: 120px;
            margin-bottom: 1em;

            svg {
                fill: c(grey);
            }
        }
    }
}