.home-categories {
    width: 100%;
    padding: 40px 0;

    &__banners {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    &__box {
        width: 33%;
        padding: 20px;

        @include under(sm) {
            width: 50%;
        }

        @include under(xs) {
            width: 100%;
        }
    }

    &__banner {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: block;

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.1);

            &:hover {
                background-color: rgba(0, 0, 0, 0);
            }

            span {
                font-family: f(serif);
                font-weight: bold;
                color: white;
                font-size: 24px;
            }
        }
    }
}