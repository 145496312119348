.splash {
	margin: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100vh - 40px);
	min-height: 650px;
	position: relative;

	@include under(sm) {
		margin: 10px;
		height: calc(100vh - 20px);
	}

	&__overlay {
		background: rgba(0, 0, 0, 0.35);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.grid__container,
	.grid__row {
		height: 100%;
	}

	&__col {
		width: 100%;
		max-width: 650px;
		height: 100%;;
		margin: 0 auto;
		display: flex; 
		align-items: center;
		justify-content: center;
	}

	&__box {
		// background-color: red;

		&--top {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
		}

		&--bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
		}
	}

	&__logo {
		height: 52px;
		font-family: f(serif);
		margin: 15px 0;

		svg {
			height: 100%;
			width: auto;
			fill: white;
		}
	}

	&__text {
		color: white;
		font-family: f(serif);
		font-size: 16px;
	}

	&__social {
		ul {
			display: flex;
			justify-content: center;
			margin: 20px 0;

			li {
				a {
					display: inline-block;
					color: white;
					font-size: 16px;
					margin: 10px 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: f(serif);
	
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__contact {
		margin: 15px auto;
		font-family: f(serif);
		text-align: center;
		color: white;

		.ttl {
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 1em;
		}

		.content {
			font-size: 14px;
			line-height: 1.5em;

			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}	

	&__form {
		font-family: f(serif);
		margin: 40px 0;

		.mc-field-group {
			label {
				color: white;
				font-size: 14px;
				margin-bottom: 10px;
				display: inline-block;
			}

			input {
				border: 0;
				color: black;
				font-size: 14px;
				font-family: f(serif);
				padding: 10px;
			}

			.mce_inline_error {
				font-size: 12px;
				padding: 10px !important;
			}
		}

		.response {
			font-size: 14px;
			color: white;
			display: inline-block;
			margin: 10px 0;
		}

		#mc-embedded-subscribe {
			margin: 10px 0;
			padding: 10px;
			border: 1px solid white;
			background-color: transparent;
			color: white;
			font-family: f(serif);
			font-size: 14px;

			&:hover {
				background-color: white;
				color: black;
				cursor: pointer;
			}
		}
	}

}