.page {

    &__img {
        margin: 1em auto;
        width: auto;

        &-box {
            margin-bottom: 40px;
        }
    }

    &-content {
        margin: 40px 0;
        width: 100%;

        &__container {
            width: 100%;
            max-width: 650px;
            margin: 0 auto;
        }
    }

    &__ttl {
        font-family: f(display);
        font-size: 54px !important;
        text-align: center;
        margin-bottom: 0.7em;
    }

    &__text {
        font-size: 18px;
        line-height: 1.5;

        h1, h2, h3, h4 h5, h6 {
            font-size: 1.2em;
            margin-bottom: 0.8em;
            font-weight: bold;
        }

        p, dl, ol, ul {
            margin-bottom: 1em;
        }

        ol {
            list-style-type: upper-roman;
            padding-left: 2em;
        }

        ul {
            list-style-type: disc;
            padding-left: 2em;
        }

        li {
            margin-bottom: 0.5em;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}