.main-wrap {
    flex: 1 0 auto;
    @extend .grid__container;
    margin-top: 40px;
    margin-bottom: 40px;
    
    font-size: 14px;
    line-height: 1.5;

    h1, h2, h3, h4 h5, h6 {
        font-size: 1.2em;
        // margin-bottom: 0.8em;
        font-weight: bold;
        display: block;
        width: 100%;

        &.page-title,
        &.ttl {
            // text-transform: uppercase;
            font-size: 24px;
            // margin-top: 40px;
        }
    }

    p, dl, ol, ul {
        margin-bottom: 1em;
        width: 100%;
    }

    ol {
        list-style-type: upper-roman;
        padding-left: 2em;
    }

    ul {
        list-style-type: disc;
        padding-left: 2em;
    }

    li {
        margin-bottom: 0.5em;
    }

    img {
        width: 100%;
        height: auto;
    }
}