html, body {
	height: 100%;
	font-size: 16px;
}
body {
	display: flex;
	flex-direction: column;	
	font-family: f(sans);
	// padding-top: 84px;

	&.with-store-notice {
		// padding-top: calc(84px + 48px + 40px);

		// @include under(sm) {
		// 	padding-top: calc(64px + 48px + 40px);
		// }
	}
}
.main-wrap {
	
}

.content-area {
	width: 100%;
}
