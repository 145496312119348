.vip {
    padding: 80px 0;
    text-align: center;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        height: 1px;
        width: 40px;  /* or 100px */
        border-bottom: 2px solid $colorForeground;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        height: 1px;
        width: 40px;  /* or 100px */
        border-bottom: 2px solid $colorForeground;
    }

    &__ttl {
        font-family: f(serif);
        font-size: 42px;
        margin-bottom: 0.9em;
    }

    &__button {
        background-color: $colorHighlight;
        color: $colorHighlightText;
        letter-spacing: 1px;
        font-family: f(sans);
        padding: 10px 15px;
        font-size: 18px;
        font-weight: bold;

        &:hover {
            opacity: 0.8;
        }
    }
}