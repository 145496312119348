/// Crea 3 paddings, 1 para cada elemento ABA
///  padding distriuido proporcionalmente: El padding total que tiene A y que tiene B deben ser iguales
///             A p_total = padA = 1 padding
///             B p_total = padB  = 2 paddings, B tiene padding derecha e izquierda
///             Si: 50/3 = 16.666666
///             padA = 1 * 16.666666
///             padB = 2 * 16.666666
///
/// @param {number} gutter: padding de la columna
///
/// @example    .col-1-3 {@include ternary-column-padding(50px);}
///
/// @author Diego Villaseñor y Cecilia M. Borja
/// @since 0.0.1
///

@mixin cuaternary-column-padding($gutter:0, $reset: false) {
    @if ($reset) {
        &:nth-of-type(4n-3) {
            padding-right: 0;
        }

        &:nth-of-type(4n-2) {
            padding-left: 0;
            padding-right: 0;
        }

        &:nth-of-type(4n-1) {
            padding-right: 0;
            padding-left: 0;
        }

        &:nth-of-type(4n) {
            padding-left: 0;
        }
    } @else {
        &:nth-of-type(4n-3) {
            padding-right: gutter-calculator($gutter, outer);
        }

        &:nth-of-type(4n-2) {
            padding-right: gutter-calculator($gutter, inner);
            padding-left: gutter-calculator($gutter, inner);
        }

        &:nth-of-type(4n-1) {
            padding-right: gutter-calculator($gutter, inner);
            padding-left: gutter-calculator($gutter, inner);
        }

        &:nth-of-type(4n) {
            padding-left: gutter-calculator($gutter, outer);
        }
    }
}
