.instagram {
    padding: 80px 0;
    
    &__panel {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    &__ttl {
        font-family: f(sans);
        font-size: 18px;
        margin-bottom: 0.8em;
        display: block;
        text-align: center;
        color: c(grey);
    }

    &__post {
        min-width: 160px;
        display: inline-block;
        margin: 2.5px;

        @include under(sm) {
            min-width: 150px;
        }
    }

    &__box {
        display: block;
        width: 100%;
        padding-bottom: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        &-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: transparent;
            font-size: 18px;
            
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                color: white;
            }
        }
    }

    &__image {

    }
}