.not-found {
    width: 100%;
    padding: 40px 0;

    &__col {
        text-align: center;
    }

    &__logo {
        width: 200px;
        margin: 20px auto;
    }

    &__ttl {
        font-family: f(display);
        font-weight: normal !important;
        color: c(black);
        font-size: 72px !important;
        line-height: 0.7;
        margin: 42px auto;

        small {
            font-family: f(sans);
            font-weight: bold;
            font-size: 32px !important;
        }
    }

    &__content {
        a {
            background-color: #EBE9EB;
            color: #545454;
            border: 0;
            padding: 10px;
            margin: 10px;
            display: inline-block;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 3px;
        }
    }
}