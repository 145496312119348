$colors: (
	black: #212121,
	white: #FFFFFF,
	pink: #FC4482,
	grey: #666666,
);

@function c($color:base) {
	@return map-get($colors, $color);
}

// Foreground
$colorForeground: c(black);
$colorForegroundLighten: lighten($colorForeground, 10%);
$colorForegroundDarken: darken($colorForeground, 10%);

$colorForegroundText: c(white);

// Background
$colorBackground: c(white);

// Highlight
$colorHighlight: c(pink);
$colorHighlightLighten: lighten($colorHighlight, 40%);
$colorHighlightDarken: darken($colorHighlight, 40%);

$colorHighlightText: c(white);

// Featured
$colorFeatured: c(base);
$colorError: #8A1010;