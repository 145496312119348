.row {
    display: flex;
    flex-flow: row wrap;
}

.col-md-12 {
    padding: 0 20px;
    width: 100%;
}

.col-md-4 {
    padding: 0 20px;
    width: 33%;
}

.col-md-8 {
    padding: 0 20px;
    width: 66%;
}