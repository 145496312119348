@mixin hamburger($dimensions){
    cursor: pointer;
    display: block;
    height: $dimensions;
    position: relative;
    width: $dimensions;
    
    span {
        background-color: $colorForeground;
        display: block;
        height: 2px;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        top: 50%;
        transition: none 0.3 ease;
        transition-property: background, left, opacity, transform;
        width: 20px;
        
        &:nth-child(1){
            margin-top: -6px;
        }
        
        &:nth-child(2){
            margin-top: -1px;
        }
        
        &:nth-child(3){
            margin-top: 4px;
        }
    }
    
    &:hover {
        background-color: transparent;
    }
    
    // Modifers
    &.is-active {
        
        span {  
            background-color: $colorForeground;
            
            &:nth-child(1){
                margin-left: -5px;
                transform: rotate(45deg);
                transform-origin: left top;
            }
            
            &:nth-child(2){
                opacity: 0;
            }
            
            &:nth-child(3){
                margin-left: -5px;
                transform: rotate(-45deg);
                transform-origin: left bottom;
            }

        }
    }
}