//_________________________ Config:Fuentes _________________________ \\

/**
 * Declare fonts using the Mixin
 */

/// Refer to font families through mapped variables.
/// Font families must be written as lists, that is, between parenthesis.
///
///

@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-Light', 300);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-LightItalic', 300, italic);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-Regular', 400);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-Italic', 400, italic);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-Medium', 500);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-MediumItalic', 500, italic);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-Semibold', 600);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-SemiboldItalic', 600, italic);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-Bold', bold);
@include declara-font-face(HKGrotesk, '../fonts/HKGrotesk/HKGrotesk-BoldItalic', bold, italic);

$fonts:(
    serif: ('Playfair Display', serif),
    sans: ('HKGrotesk', sans-serif),
    display: ('Abril Fatface', sans-serif)
);

@function f($font) {
	@return map-get($fonts, $font);
}
