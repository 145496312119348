$primary: c(black);
$primarytext: c(white);

$secondary: c(white);
$secondarytext: c(grey);

$highlight: c(pink);
$highlightext: c(white);

@import 'mixins';

.regalaemociones-theme {

	p.demo_store,
	.woocommerce-store-notice {
		position: relative;
		box-shadow: none;
		font-size: 0.9rem;
		background: $secondary;
		color: $secondarytext;

		a {
			display: none;
		}
	}

	.woocommerce,
	&.woocommerce {
		width: 100%;
		// padding: 20px 0;
		
		.woocommerce-breadcrumb {
			padding-top: 30px;
			padding-bottom: 30px;
			margin-bottom: 0;

			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		
		span.onsale {
			min-height: auto;
			min-width: auto;
			letter-spacing: 1px;
			text-transform: uppercase;
			background-color: $highlight;
			color: $highlightext;
			border-radius: 0;
			padding: 0 10px;
		}
		
		div.product {
			form.cart {
				div.quantity {
					display: none !important;
				}
				.variations {
					td {
						padding-bottom: 10px;

						&.label {
							width: 1px;
						}
					}
					select {
						padding: 10px;
						border-radius: 0;
						font-size: 12px;
						background-color: white;
					}
				}
			}
			div.summary {
				p.price {
					text-align: left;
				}
			}
			.button {
				width: 100%;
				background: $highlight;
				color: $highlightext;
				text-transform: uppercase;
				font-family: f(sans);
				font-size: 1.1rem;

				@include over(sm) {
					max-width: 400px;
				}
			}
			.product_title {
				font-family: f(display);
				font-size: 36px;
				margin-bottom: 0.5em;
				font-weight: 400;
			}
			span.price,
			p.price {
				color: $highlight;
				margin-bottom: 0.74em;
				text-align: center;

				ins {
					text-decoration: none;
				}

				del {
					//
				}
			}
			div.images {
				margin-bottom: 3em;
			}
		}
		
		ul.products li.product {
			h3,
			.woocommerce-loop-product__title,
			.woocommerce-loop-category__title {
				text-align: center;
				font-family: f(sans);
			}
			.price {
				color: $highlight;
				text-align: center;
	
				del {
					//
				}
	
				ins {
					text-decoration: none;
				}
	
				.from {
					font-size: 0.67em;
					margin: -2px 0 0 0;
					text-transform: uppercase;
					color: rgba(desaturate($highlight, 75%), 0.5);
				}
			}
		}

		.woocommerce-ordering {
			margin: 0 0 2em;
	
			select {
				vertical-align: top;
				padding: 10px;
				border: 1px solid c(black);
				border-radius: 0;
				background-color: white;
				font-size: 13px;
				font-weight: 300;
			}
		}

		.quantity {
			.qty {
				padding: 10px;
				width: 4em;
			} 
		}

		.cart {
			.button,
			input.button {
				// background-color: c(black);
				// color: white;
				border-radius: 0;
			}
		}

		input.button.alt,
		button.button.alt,
		a.button.alt {
			background-color: c(pink);
		}

		table.cart td.actions {

			.coupon {
				display: flex;
				align-items: stretch;
				flex: 1;
			}

			input,
			button {
				flex: 1;
			}

			.input-text {
				width: 180px;
			}
		}

		.cart-collaterals {
			.cart_totals {
				h2 {
					font-size: 24px;
					margin-bottom: 1em;
				}
			}
		}

		table.shop_table {
			border: 0;
		}

		form.checkout_coupon,
		form.login,
		form.register {
			padding-left: 0;
			padding-right: 0;
			border-radius: 0;
			border: 0;
			border-bottom: 1px solid #d3ced2;
		}
	}

	.woocommerce-product-details__short-description {
		margin-bottom: 1em;
	}

	.woocommerce-products-header__title {
		font-family: f(serif);
		font-size: 32px;
		margin-bottom: 1em;
	}

	.product_meta {
		display: flex;
		flex-direction: column;

		span {
			margin-bottom: 1em;

			span {
				color: $secondarytext;
			}

			a {
				text-decoration: underline;
			}
		}
	}

	.cross-sells,
	.upsells.products,
	.related.products {
		clear: both;

		h2 {
			font-family: f(sans);
			font-size: 21px;
			margin-top: 2px;
			margin-bottom: 2.5em;
		}
	}

	.single_variation_wrap {
		.woocommerce-variation.single_variation{
			margin-bottom: 2em;

			.woocommerce-variation-description {
				line-height: 1.3;
			}
		}
	}

	// Mensajes

	.woocommerce-error, 
	.woocommerce-info,
	.woocommerce-message {
		background-color: c(white);
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 2em 0 1em 0;
		width: 100%;

		.button {
			order: 1;
			background-color: transparent;
		}

		&:after {
			content: none;
		}

		strong {
			font-weight: bold;
		}

		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	ul.woocommerce-error {
		flex-direction: column;
	}

	.woocommerce-message {
		border-top-color: c(pink);

		&::before {
			color: c(pink);
			top: 1.5em;
		}

		.wc-forward {
			background-color: transparent;
			color: c(pink);
		}
	}

	.woocommerce-info {
		border-top-color: c(black);

		&:before {
			color: c(black);
		}
	}

	// Carrito

	.shipping-calculator-form {
		padding: 20px 0;
	}

	.shipping-calculator-button {
		text-decoration: underline;
	}

	.woocommerce-cart-form {
		margin: 40px 0;
	}

	.select2-container--default .select2-selection--single {
		border-radius: 0;
	}

	.input-text {
		padding: 9px !important;
		font-size: 13px;
	}

	// Checkout 

	form {
		.form-row {
			.input-checkbox {
				display: inline-block;
			}
		}
	}

	#customer_details {
		margin-bottom: 2em;
	}

	#order_review_heading {
		font-size: 24px;
		margin-bottom: 1em;
	}

	.woocommerce-checkout {
		#payment {
			background-color: transparent;
		}
	}

	// Mi cuenta

	.woocommerce-MyAccount-navigation {
		width: 100%;
		margin-bottom: 1em;

		ul {
			display: flex;
			flex-flow: row wrap;
			
			li {

				a {
					display: block;
					margin: 1em 2em 1em 0;

					&:hover {
						text-decoration: underline;
					}
				}

				&.is-active {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.woocommerce-MyAccount-content {
		width: 100%;

		p {
			margin-bottom: 0.8em;
		}

		.woocommerce-Addresses {
			margin: 2em 0;
		}

		.woocommerce-Address-title {
			font-weight: bold;
			margin-bottom: 1em;
		}
	}

	table.cart {
		img {
			width: 80px;
		}
	}

	#payment {
		background-color: white;

		ul.payment_methods {
			li {
				img {
					width: auto !important;
					display: inline-block;
					// height: 24px;
				}
				a {
					text-decoration: underline;
				}
				h4 {
					font-weight: bold;
					margin-bottom: 1em;
					font-size: 15px;
				}
			}
		}

		div.payment_box {
			background-color: white;
			border: 1px solid whitesmoke;

			&:before {
				display: none;
			}

			.form-row {
				padding: 0;

				select {
					padding: 10px;
					width: 100%;
					border-radius: 0;
					font-size: 13px;
					background-color: white;
				}

				input {
					border-radius: 0 !important;
				}
			}
		}
		#place_order {
			font-size: 18px;
		}

		.payment_method_paypal {
			.about_paypal {
				text-decoration: underline;
			}
		}
	}
}

#byconsolewooodt_delivery_type_field {
	display: none;
}

label span.optional {
	display: none;
}