/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
@mixin clearfix() {
	*zoom: 1;

	&::before,
	&::after {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius($radius: 4px) {
	border-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_right($radius: 4px) {
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_left($radius: 4px) {
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_bottom($radius: 4px) {
	border-bottom-left-radius: $radius;
	border-bottom-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_top($radius: 4px) {
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin opacity( $opacity: 0.75 ) {
	opacity: $opacity;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin box_shadow($shadow_x: 3px, $shadow_y: 3px, $shadow_rad: 3px, $shadow_in: 3px, $shadow_color: #888) {
	box-shadow: $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin inset_box_shadow($shadow_x: 3px, $shadow_y: 3px, $shadow_rad: 3px, $shadow_in: 3px, $shadow_color: #888) {
	box-shadow: inset $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin text_shadow($shadow_x: 3px, $shadow_y: 3px, $shadow_rad: 3px, $shadow_color: #fff) {
	text-shadow: $shadow_x $shadow_y $shadow_rad $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin vertical_gradient($from: #000, $to: #fff) {
	background-color: $from;
	background: -webkit-linear-gradient($from, $to);
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin transition($selector: all, $animation: ease-in-out, $duration: 0.2s) {
	transition: $selector $animation $duration;
}

/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
@mixin scale($ratio: 1.5) {
	-webkit-transform: scale($ratio);
	transform: scale($ratio);
}

/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
@mixin borderbox() {
	box-sizing: border-box;
}

@mixin darkorlighttextshadow($a, $opacity: 0.8) {
	@if lightness($a) >= 65% {
		@include text_shadow(0, -1px, 0, rgba(0, 0, 0, $opacity));
	}
	@else {
		@include text_shadow(0, 1px, 0, rgba(255, 255, 255, $opacity));
	}
}

/**
 * Objects
 */
@mixin menu() {
	@include clearfix();

	li {
		display: inline-block;
	}
}

@mixin mediaright() {
	@include clearfix();

	img {
		float: right;
		height: auto;
	}
}

@mixin medialeft() {
	@include clearfix();

	img {
		float: right;
		height: auto;
	}
}

@mixin ir() {
	display: block;
	text-indent: -9999px;
	position: relative;
	height: 1em;
	width: 1em;
}

@mixin icon( $glyph: '\e001' ) {
	font-family: 'WooCommerce';
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	margin: 0;
	text-indent: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	content: $glyph;
}

@mixin icon_dashicons( $glyph: '\f333' ) {
	font-family: 'Dashicons';
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	text-indent: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	content: $glyph;
}

@mixin iconbefore( $glyph: '\e001' ) {
	font-family: 'WooCommerce';
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin-right: 0.618em;
	content: $glyph;
	text-decoration: none;
}

@mixin iconbeforedashicons( $glyph: '\f333' ) {
	font-family: 'Dashicons';
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	content: $glyph;
	text-decoration: none;
}

@mixin iconafter( $glyph: '\e001' ) {
	font-family: 'WooCommerce';
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin-left: 0.618em;
	content: $glyph;
	text-decoration: none;
}

@mixin loader() {
	&::before {
		height: 1em;
		width: 1em;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -0.5em;
		margin-top: -0.5em;
		content: '';
		animation: spin 1s ease-in-out infinite;
		background: url('../images/icons/loader.svg') center center;
		background-size: cover;
		line-height: 1;
		text-align: center;
		font-size: 2em;
		color: rgba(#000, 0.75);
	}
}
