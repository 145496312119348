.navbar-search {
    padding: 20px 0;
    position: relative;
    border-top: 1px solid whitesmoke;
    display: none;

    form {
        display: flex;
    }

    input {
        padding: 10px;
        font-size: 13px;
    }

    button[type="submit"] {
        width: 150px;
        font-size: 13px;
        text-transform: uppercase;
        background-color: $colorForeground;
        color: $colorForegroundText;
        border: 0;
        cursor: pointer;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        border: 0;
        font-size: 14px;
        background-color: transparent;

        @include under(sm) {
            top: 0px;
            right: 0px;
        }
    }
}